import { useCallback, useState } from "react";

const useOverlay = () => {
  const [overlay, setOverlay] = useState(false);

  const showOverlay = useCallback(() => {
    if (!overlay) {
      setOverlay(true);
    }
  }, [overlay]);

  const hideOverlay = useCallback(() => {
    if (overlay) {
      setOverlay(false);
    }
  }, [overlay]);

  return { overlay, showOverlay, hideOverlay };
};

export default useOverlay;
