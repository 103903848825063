import { NavigationItem } from "types/common";

export const hasChildWithThisPath = (item: NavigationItem, path: string): boolean => {
  if (item.href === path) return true;
  if (item.children?.length) {
    return item?.children.some((child) => hasChildWithThisPath(child, path));
  }
  return false;
};

export const pathIsActive = (href: any, path?: any, isRecordPage?: boolean, isAdminPage?: boolean) => {
  if (typeof path !== "string" && typeof href !== "string") return false;

  if (path === href) return true;

  if (isAdminPage && href && path.includes(href)) return true;

  if ((isRecordPage || isAdminPage) && path !== href) {
    return false;
  }

  const pathParts = path?.split("/") || [];
  const hrefParts = href?.split("/") || [];

  if (pathParts.length !== hrefParts.length) return false;
  if (pathParts?.[1] === hrefParts?.[1] && pathParts?.[2] === hrefParts?.[2]) {
    return true;
  }

  return false;
};
