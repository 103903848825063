import { useQuery } from "@tanstack/react-query";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { ADMIN_PAGE_SLUG_TO_PATH_MAP } from "app/(authRoutes)/(global)/admin/utils";
import { getMenusByPage } from "lib/adminApi";
import { ApiPage } from "types/apiTypes";
import { MenuItem, Page } from "types/baTypes";
import { NavigationItem, PAGE_TYPES, QueryHookOptions, RecordItem } from "types/common";
import { transformMenuItemActions } from "utils/actions";
import { USER_TYPE, ViewOption, TILES_MENU_ITEM_TABLE } from "utils/constants";
import { withNestedChildren } from "utils/dataUtils";
import { getPageViewRoute, mapApiMenuItemToMenuItem, mapApiPageToPage } from "utils/pageUtils";
import { getDefaultNavigationItem } from "utils/pathUtils";
import useCurrentUser from "./useCurrentUser";
import useRecordDataForMenuItems from "./useRecordDataForMenuItems";

const useNavigationMenu = ({
  isMain,
  pageId,
  recordSlug,
  recordId,
  allPages,
  hookOptions
}: {
  recordSlug?: string;
  pageId: string | null;
  recordId?: string;
  isMain?: boolean;
  isAdminMenu?: boolean;
  allPages?: Page[];
  hookOptions?: QueryHookOptions;
}) => {
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const supabaseClient = useSupabaseBrowser();
  const user = useCurrentUser();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["ui_menu", pageId, user?.type, user?.org_id],
    queryFn: () => getMenusByPage(supabaseClient, pageId, user?.type, user?.org_id),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!user,
    ...hookOptions
  });

  const menuItemsForRecord = useMemo(() => {
    if (!data?.data) return [];
    const tileItems = data?.data
      .flatMap((menu) => menu.ui_menu_items || [])
      .filter((menuItem) => !!menuItem?.record_config);

    return tileItems;
  }, [data?.data]);

  const {
    data: recordMenuItemsData,
    isLoading: recordMenuItemsLoading,
    isFetched
  } = useRecordDataForMenuItems(menuItemsForRecord, {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...hookOptions
  });

  const finalMenuItems: Array<MenuItem & RecordItem> = useMemo(() => {
    if (!data?.data || (menuItemsForRecord.length && recordMenuItemsLoading)) return [];

    return data.data
      .flatMap((menu) => menu.ui_menu_items || [])
      .filter((menuItem) =>
        !user?.is_admin ? !(menuItem.ui_page_id?.is_admin_page || menuItem.show_admin_only) : true
      )
      .map((menuItem) => mapApiMenuItemToMenuItem(menuItem))
      .reduce((acc: Array<MenuItem & RecordItem>, menuItem) => {
        const actions =
          user?.type === USER_TYPE.STAFF && user.is_admin
            ? menuItem.ui_menu_actions?.map(transformMenuItemActions) || []
            : [];
        let itemHref = "";
        let tabAddManyPageId = undefined;
        let defaultAddExpanded = false;
        let pageTabId = undefined;
        const menuPage =
          !menuItem.ui_page_id ||
          menuItem.ui_page_id?.is_admin_page ||
          menuItem.ui_page_id?.page_type === PAGE_TYPES.STATIC ||
          menuItem.ui_page_id?.metabase_dashboard_id
            ? undefined
            : allPages?.find((page) => `${page.id}` === `${menuItem.ui_page_id?.id}`);
        if (menuItem.ui_page_id && isMain && !menuItem.additionalConfig?.showAsCreateForm) {
          const viewToUse = getPageViewRoute(menuItem.ui_page_id, isMobile);
          itemHref = menuItem.ui_page_id?.is_admin_page
            ? `/admin/${ADMIN_PAGE_SLUG_TO_PATH_MAP[menuItem.ui_page_id.path]}`
            : `/table${menuItem.ui_page_id?.path}/${viewToUse.replace("_", "")}`;
          if (menuItem.ui_page_id.metabase_dashboard_id) {
            itemHref = `/reports${menuItem.ui_page_id.path}`;
          }
          if (menuItem.ui_page_id.page_type === PAGE_TYPES.STATIC) {
            itemHref = `/p${menuItem.ui_page_id.path}`;
          }
        } else if (menuItem.url) {
          itemHref = menuItem.url;
        } else if (menuItem.record_config) {
          const menuItemRecord = recordMenuItemsData?.find((item) => item.id === menuItem.id)?.record;
          if (menuItemRecord && menuItem.record_config.table_name === TILES_MENU_ITEM_TABLE) {
            itemHref = `/t/${menuItemRecord.public_id}`;
          }
        } else if (!isMain && !menuItem.additionalConfig?.showAsCreateForm && recordSlug && recordId) {
          const recordPage = data?.data?.[0]?.pages?.map((page) => mapApiPageToPage(page as ApiPage))?.[0];
          const pageDetailViewTabs = recordPage?.views?.find((view) => view.viewType === ViewOption.DETAIL_MAIN)?.tabs;
          if (pageDetailViewTabs?.length) {
            const tabInfo = pageDetailViewTabs.find((tab) => tab.uiMenuItemId === menuItem.id);

            if (tabInfo) {
              itemHref = `/r/${recordSlug}/${recordId}/${tabInfo?.page?.path?.replace("/", "")}`;
              tabAddManyPageId = tabInfo?.addPage?.id;
              defaultAddExpanded = !!tabInfo?.defaultAddExpanded;
              pageTabId = tabInfo?.tabId;
            }
          }
        }
        const menuPageFormView = menuPage?.views?.find((view) => view.viewType === ViewOption.FORM);
        let isFormEnabled = !!menuPageFormView?.id && !menuPageFormView?.additionalConfig?.multiFormEnabled;
        let tabForPage = undefined;
        if (!menuPage && menuItem?.tab_id && recordSlug) {
          const recordPage = allPages?.find(
            (page) => page.path?.replace("/", "") === recordSlug.replace("/", "") && page.user_type === user?.type
          );
          if (recordPage) {
            const pageDetailViewTabs = recordPage?.views?.find(
              (view) => view.viewType === ViewOption.DETAIL_MAIN
            )?.tabs;
            const menuTab = pageDetailViewTabs?.find((tab) => tab.tabId === menuItem.tab_id);
            if (menuTab?.page?.id) {
              tabForPage = allPages?.find((page) => page.id === menuTab.page?.id);
              if (tabForPage?.id) {
                const tabPageView = tabForPage.views?.find((view) => view.viewType === ViewOption.FORM);
                isFormEnabled = !!tabPageView?.id && !tabPageView?.additionalConfig?.multiFormEnabled;
              }
            }
          }
        }
        if (menuItem.record_config) {
          if (itemHref) {
            acc.push({
              ...menuItem,
              href: itemHref,
              actions,
              icon: menuItem.icon,
              isAdminMenuItem: !!menuItem.ui_page_id?.is_admin_page || menuItem.show_admin_only,
              isFormEnabled,
              pageTitle: menuPage?.title,
              pageSlug: menuPage?.path || menuItem.ui_page_id?.path,
              tabForPage,
              tabAddManyPageId,
              defaultAddExpanded,
              pageTabId
            });
          }
        } else {
          acc.push({
            ...menuItem,
            href: itemHref,
            actions,
            icon: menuItem.icon,
            isAdminMenuItem: !!menuItem.ui_page_id?.is_admin_page || menuItem.show_admin_only,
            isFormEnabled,
            pageTitle: menuPage?.title,
            pageSlug: menuPage?.path || menuItem.ui_page_id?.path,
            tabForPage,
            tabAddManyPageId,
            defaultAddExpanded,
            pageTabId
          });
        }

        return acc;
      }, []);
  }, [
    data?.data,
    user?.type,
    user?.is_admin,
    isMain,
    recordSlug,
    recordId,
    isMobile,
    menuItemsForRecord?.length,
    recordMenuItemsData,
    recordMenuItemsLoading,
    allPages
  ]);

  const finalMenuNavigationItems: NavigationItem[] = useMemo(() => {
    const groupedMenuItems = groupBy(finalMenuItems, "parent_id");

    return (groupedMenuItems["null"] || [])
      .map((parentItem) => withNestedChildren(parentItem, groupedMenuItems, "id"))
      .filter(
        (menuItem) =>
          !(
            (menuItem.is_folder || menuItem.is_section || menuItem.additionalConfig?.showAsCreateForm) &&
            !menuItem.children.length &&
            !menuItem.is_divider &&
            (!user?.is_admin ? !menuItem.show_admin_only : true)
          )
      )
      .map((menuItem) => ({
        id: menuItem.id,
        name: menuItem.name,
        href: menuItem.href,
        actions:
          user?.type === USER_TYPE.STAFF && user?.is_admin
            ? menuItem.ui_menu_actions?.map(transformMenuItemActions) || []
            : [],
        children: menuItem.children,
        is_divider: menuItem.is_divider,
        icon: menuItem.icon,
        ui_page_id: menuItem.ui_page_id,
        is_section: menuItem.is_section,
        is_folder: menuItem.is_folder,
        show_admin_only: menuItem.show_admin_only,
        show_record_count: menuItem.show_record_count,
        additionalConfig: menuItem.additionalConfig,
        open_in_new_tab: menuItem.open_in_new_tab,
        isAdminMenuItem: menuItem.isAdminMenuItem,
        ui_menu_id: menuItem.ui_menu_id,
        sort_order: menuItem.sort_order,
        parent_id: menuItem.parent_id,
        isFormEnabled: menuItem.isFormEnabled,
        pageTitle: menuItem.pageTitle,
        pageSlug: menuItem?.pageSlug,
        tabForPage: menuItem?.tabForPage,
        tabAddManyPageId: menuItem?.tabAddManyPageId,
        defaultAddExpanded: menuItem?.defaultAddExpanded,
        pageTabId: menuItem?.pageTabId
      }));
  }, [finalMenuItems, user?.type, user?.is_admin]);

  const defaultMenuItem = useMemo(
    () =>
      (menuItemsForRecord.length ? isFetched : true) && finalMenuNavigationItems.length
        ? getDefaultNavigationItem(finalMenuNavigationItems)
        : null,
    [finalMenuNavigationItems, isFetched, menuItemsForRecord.length]
  );

  const menuPosition = data?.data?.[0]?.position;
  const menuIsLeft = menuPosition === "left";
  const menuIsTop = menuPosition === "top";

  return {
    data: finalMenuNavigationItems,
    menuItems: finalMenuItems,
    isLoading: isLoading || recordMenuItemsLoading,
    refetch,
    defaultMenuItem,
    menuId: data?.data?.[0]?.id,
    menuPosition,
    menuIsLeft,
    menuIsTop
  };
};

export default useNavigationMenu;
