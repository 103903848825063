import { useQuery } from "@tanstack/react-query";
import sortBy from "lodash/sortBy";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getRecordDataForMenuItems } from "lib/supabaseApi";
import { MenuItem } from "types/baTypes";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

const useRecordDataForMenuItems = (menuItems: MenuItem[], hookOptions?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: ["record_menu_items", ...sortBy(menuItems, "id").map((item) => item.id)],
    queryFn: () =>
      getRecordDataForMenuItems({
        menuItems,
        supabaseClient,
        currentUser
      }),
    enabled: !!menuItems.length,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: Infinity,
    ...(hookOptions || {})
  });

  return { data, isLoading: isLoading && !!menuItems?.length, refetch, isFetched };
};

export default useRecordDataForMenuItems;
