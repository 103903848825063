import { createElement } from "react";
import clsx from "clsx";
import { ChevronRightIcon, FolderIcon, FolderOpenIcon } from "lucide-react";

type MenuSectionProps = {
  name: string;
  isOpen: boolean;
  isCollapsed: boolean;
};

const MenuSection = ({ name, isOpen, isCollapsed }: MenuSectionProps) => {
  return (
    <div className="flex w-full items-center justify-between px-6 py-4 text-[15px] text-gray-700 transition-transform hover:bg-gray-200/40 hover:text-gray-800 active:scale-[0.98] dark:text-gray-300 dark:hover:bg-gray-750 dark:hover:text-gray-200">
      <div className="flex items-center gap-x-2">
        {createElement(isOpen ? FolderOpenIcon : FolderIcon, {
          className:
            "h-4 w-4 flex-shrink-0 transition-colors group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400"
        })}
        <div
          className={clsx(
            "font-medium tracking-wide focus:outline-none",
            isCollapsed ? "hidden group-hover/nav:inline" : "inline"
          )}
        >
          {name}
        </div>
      </div>

      <ChevronRightIcon className={clsx(isOpen ? "rotate-90" : "", "h-5 w-5 text-gray-400 transition-transform")} />
    </div>
  );
};

export default MenuSection;
