import { NavigationItem } from "types/common";

export const transformNavItemsToNavigate = (navItems: NavigationItem[], parentLayout?: "record" | "nested") => {
  const itemsToNavigate: NavigationItem[] = [];

  navItems.forEach((item) => {
    if (parentLayout === "record") {
      if (item.href && !item.children?.length) {
        itemsToNavigate.push(item);
      } else if (item.children?.length) {
        item.children.forEach((child) => {
          if (child.href && !child.children?.length) {
            itemsToNavigate.push(child);
          } else if (child.children?.length) {
            child.children.forEach((subChild) => {
              if (subChild.href && !subChild.children?.length) {
                itemsToNavigate.push(subChild);
              }
            });
          }
        });
      }
    } else if (parentLayout === "nested") {
      if (item.onClick && !item.children?.length) {
        itemsToNavigate.push(item);
      } else if (item.children?.length) {
        item.children.forEach((child) => {
          if (child.onClick && !child.children?.length) {
            itemsToNavigate.push(child);
          } else if (child.children?.length) {
            child.children.forEach((subChild) => {
              if (subChild.onClick && !subChild.children?.length) {
                itemsToNavigate.push(subChild);
              }
            });
          }
        });
      }
    }
  });

  return itemsToNavigate;
};
