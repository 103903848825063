import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import { useQueryClient } from "@tanstack/react-query";
import {
  getFileDefaultValues,
  getFileJoinLookupTableDefaultValues,
  getFileJoinTableDefaultValues,
  processFilesForUpload
} from "utils/file";
import { FORM_TRIGGER_TYPES } from "utils/constants";
import { getTableDataById } from "lib/supabaseApi";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { FileUploadResults, RecordItem } from "types/common";
import { AddInput } from "types/apiTypes";
import useRelationPages from "./useRelationPages";
import useSchemaState from "./useSchemaState";
import useSearchQueryParams from "./useSearchQueryParams";
import useTableActionsState from "./useTableActionsState";
import useCurrentUser from "./useCurrentUser";
import useAllPagesLite from "./useAllPagesLite";
import useUserType from "./useUserType";
import useAddRecord from "./useAddRecord";
import useUpdateRecord from "./useUpdateRecord";
import useMultiFilesUpload from "./useMultiFilesUpload";

export type UploadFilesProps = {
  pageId?: string;
  isFilePage: boolean;
  tableName?: string;
  trigger?: FORM_TRIGGER_TYPES;
  parentRecordInfo?: {
    path?: string;
    recordId?: string;
    tableName?: string;
    pageId?: string;
  };
  uploadPath?: string;
  createdIn?: string;
  createdInPath?: string;
  returnFiles?: boolean;
  onClose?: (...args: RecordItem[]) => void;
  onFilesChange?: (files: File[]) => void;
  filesListId?: string;
  onSuccess?: (results?: FileUploadResults, triggeredBy?: string) => void;
  skipFilesListCreate?: boolean;
  newFilesToUpload?: File[];
  uploadedFilesFromDragAndDrop?: RecordItem[];
};
const useUploadFiles = ({
  pageId,
  isFilePage,
  tableName = "files",
  trigger,
  parentRecordInfo,
  uploadPath,
  createdIn,
  createdInPath,
  returnFiles = false,
  onClose = noop,
  onFilesChange = noop,
  filesListId,
  onSuccess,
  skipFilesListCreate,
  newFilesToUpload,
  uploadedFilesFromDragAndDrop
}: UploadFilesProps) => {
  const supabaseClient = useSupabaseBrowser();
  const { schemaInstance } = useSchemaState();
  const queryClient = useQueryClient();
  const { data: relationPages, getRelationPages } = useRelationPages();
  const { currentRecordId, currentProjectId, currentTablePage, updateBulkActionsStateByTableSlug } =
    useTableActionsState();
  const { updateRecordAsync } = useUpdateRecord();
  const [parentFilesListId, setParentFilesListId] = useState<string>();
  const { addRecordAsync } = useAddRecord();
  const currentUser = useCurrentUser();
  const { userType } = useUserType();
  const filesListIdFetchPageIdRef = useRef<string | undefined>();
  const [isUploading, setIsUploading] = useState(false);
  const [isCurrentFilesSetUpload, setIsCurrentFilesSetUpload] = useState(false);
  const [uploadingQueue, setUploadingQueue] = useState<File[]>([]);
  const { uploadStatus, startUpload, rejectFiles } = useMultiFilesUpload();
  const { data: allPages } = useAllPagesLite({
    refetchOnWindowFocus: false
  });
  const [uploadResults, setUploadResults] = useState<FileUploadResults>({
    uploadCount: 0,
    uploadedFiles: [],
    errorFileData: []
  });
  useEffect(() => {
    if (!pageId || !isFilePage) return;
    getRelationPages([pageId] as string[]);
  }, [pageId, getRelationPages, isFilePage]);

  const isJoinTable = useMemo(
    () => !!schemaInstance?.extendedSchema[tableName]?.compositePk?.length,
    [tableName, schemaInstance?.extendedSchema]
  );
  const {
    currentSearchContextProjectId,
    currentSearchContextRecordId,
    updateCurrentSearchContextProjectId,
    updateCurrentSearchContextRecordId
  } = useSearchQueryParams();

  const lookupJoinDefaultValues = useMemo(() => {
    if (!relationPages?.length || !pageId) return;
    const defVals = getFileJoinLookupTableDefaultValues({
      allPages: relationPages,
      pageId,
      additionalValues: {
        currentProjectId,
        currentRecordId,
        currentUserId: currentUser?.id
      },
      extendedSchema: schemaInstance?.extendedSchema
    });

    return defVals;
  }, [relationPages, pageId, currentProjectId, currentRecordId, currentUser?.id, schemaInstance?.extendedSchema]);

  const defaultValues = useMemo(() => {
    if (!relationPages?.length || !pageId) return;
    return getFileDefaultValues(relationPages, pageId, isJoinTable, {
      currentProjectId:
        trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
          ? currentSearchContextProjectId
          : currentProjectId,
      currentRecordId:
        trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId
          ? currentSearchContextRecordId
          : currentRecordId,
      currentUserId: currentUser?.id
    });
  }, [
    relationPages,
    pageId,
    currentProjectId,
    currentRecordId,
    currentUser?.id,
    isJoinTable,
    trigger,
    currentSearchContextProjectId,
    currentSearchContextRecordId
  ]);

  const joinTableDefaultValues = useMemo(() => {
    if (!relationPages?.length || !pageId) return;
    return getFileJoinTableDefaultValues(relationPages, pageId, {
      currentProjectId:
        trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
          ? currentSearchContextProjectId
          : currentProjectId,
      currentRecordId:
        trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId
          ? currentSearchContextRecordId
          : currentRecordId,
      currentUserId: currentUser?.id
    });
  }, [
    relationPages,
    pageId,
    currentProjectId,
    currentRecordId,
    currentUser?.id,
    trigger,
    currentSearchContextProjectId,
    currentSearchContextRecordId
  ]);
  const parentRecordPage = useMemo(
    () =>
      allPages?.find(
        (page) =>
          parentRecordInfo &&
          (`${page.id}` === `${parentRecordInfo?.pageId || ""}` ||
            ((page.path || "").replace("/", "") === (parentRecordInfo.path || "").replace("/", "") &&
              page.user_type === userType))
      ),
    [allPages, parentRecordInfo, userType]
  );

  const parentRecord = useMemo(() => {
    if (!isJoinTable || !parentRecordPage?.id || !tableName) return;

    const finalRecordId = parentRecordInfo?.recordId ? parseInt(parentRecordInfo.recordId) : null;

    if (!parentRecordPage || !finalRecordId) return;

    const compositeKey = schemaInstance?.extendedSchema[tableName]?.compositePk;
    const parentRecordCompositeKey = compositeKey?.find((key) => key.table === parentRecordPage.table_name);

    if (!parentRecordCompositeKey) {
      return;
    }

    return {
      name: parentRecordCompositeKey.table,
      attribute: parentRecordCompositeKey.attributeId,
      record: {
        id: finalRecordId
      },
      key: compositeKey
    };
  }, [isJoinTable, schemaInstance?.extendedSchema, tableName, parentRecordInfo, parentRecordPage]);

  const triggeredBy = useMemo(() => {
    if (!parentRecord) {
      if (parentRecordInfo?.tableName && parentRecordInfo?.recordId) {
        return `${currentUser?.org_id}/${parentRecordInfo?.tableName}/${parentRecordInfo?.recordId}`;
      }
      return uploadPath || "general";
    }

    return `${currentUser?.org_id}/${parentRecord.name}/${parentRecord.record.id}`;
  }, [parentRecord, parentRecordInfo, uploadPath, currentUser?.org_id]);

  const getParentFilesListId = useCallback(async () => {
    if (!parentRecordPage?.id || !parentRecordInfo?.recordId) {
      return;
    }

    if (parentRecordPage?.table_name === "files_lists") {
      if (parentRecordInfo?.recordId) {
        setParentFilesListId(parentRecordInfo.recordId);
      }
      return;
    }
    const { data: parentRecordData } = await getTableDataById(
      {
        tableName: parentRecordPage.table_name,
        id: parentRecordInfo?.recordId,
        columns: [],
        source: "useUploadFiles.ts getParentFilesListId",
        organizationId: currentUser?.org_id || ""
      },
      supabaseClient
    );
    const finalRecord = parentRecordData?.[0];
    if (!finalRecord?.id) {
      return;
    }
    if (finalRecord?.files_list_id) {
      setParentFilesListId(finalRecord?.files_list_id);
    } else {
      // We need to create a files list id for this record
      const { data: newFilesListData } = await addRecordAsync({
        tableName: "files_lists",
        input: {
          created_in: createdIn,
          created_in_path: createdInPath,
          project_id:
            trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
              ? currentSearchContextProjectId
              : currentProjectId
        }
      });
      if (newFilesListData?.[0]?.id) {
        const { data: updateRecordData } = await updateRecordAsync({
          tableName: parentRecordPage.table_name,
          input: {
            id: finalRecord.id,
            files_list_id: newFilesListData?.[0]?.id
          }
        });
        if (updateRecordData?.[0]?.id) {
          setParentFilesListId(newFilesListData?.[0]?.id);
        }
      }
    }
  }, [
    parentRecordPage,
    parentRecordInfo?.recordId,
    addRecordAsync,
    updateRecordAsync,
    createdIn,
    createdInPath,
    supabaseClient,
    currentProjectId,
    trigger,
    currentSearchContextProjectId,
    currentUser?.org_id
  ]);

  const onSelectUploadedFiles = useCallback(() => {
    const currentPageSlug = (currentTablePage?.path || "").replace("/", "");

    const uploadedFilesList =
      (!isEmpty(uploadStatus) ? uploadResults?.uploadedFiles : uploadedFilesFromDragAndDrop) || [];
    if (currentPageSlug && uploadedFilesList?.length) {
      updateBulkActionsStateByTableSlug(
        {
          isSelected: true,
          tableName: tableName,
          tablePageSlug: currentPageSlug,
          uploadedFilesSelection: uploadedFilesList
        },
        currentPageSlug
      );
    }
  }, [
    currentTablePage?.path,
    tableName,
    updateBulkActionsStateByTableSlug,
    uploadResults?.uploadedFiles,
    uploadStatus,
    uploadedFilesFromDragAndDrop
  ]);

  const uploadFiles = useCallback(
    async (newFiles: File[]) => {
      if (returnFiles) {
        onFilesChange(newFiles);
        onClose({
          isOpen: false,
          currentAction: null,
          triggeredBy: null,
          actionProps: null
        });
        return;
      }

      setIsUploading(true);
      setIsCurrentFilesSetUpload(true);
      onClose({
        isOpen: false
      });
      setUploadingQueue((prevQ) => [...(prevQ || []), ...newFiles]);
      const finalFilesList = processFilesForUpload(newFiles, triggeredBy, defaultValues, {
        created_in: createdIn,
        created_in_path: createdInPath
      });
      setIsCurrentFilesSetUpload(false);
      const results = await startUpload(finalFilesList);

      const finalResult = { ...results };

      if (isJoinTable && parentRecord && tableName) {
        // update joinTable Too
        const joinTableAddQueries: AddInput[] = [];
        results.uploadedFiles.forEach((fileUploaded) => {
          const input: RecordItem = { ...joinTableDefaultValues };
          parentRecord.key?.forEach((keyField) => {
            if (keyField.table === "files") {
              input[keyField.attributeId] = fileUploaded.id;
            }
            if (keyField.attributeId === parentRecord.attribute) {
              input[parentRecord.attribute] = parentRecord.record.id;
            }
          });

          joinTableAddQueries.push({
            tableName,
            input
          });
        });

        const joinQueriesResponse = await Promise.all(joinTableAddQueries.map((query) => addRecordAsync(query)));
        joinQueriesResponse.forEach((queryResponse, index) => {
          if (queryResponse.error) {
            finalResult.uploadedFiles = finalResult.uploadedFiles.filter(
              (file) => file.id !== results.uploadedFiles[index]?.id
            );
            finalResult.errorFileData = finalResult.errorFileData
              ? [...finalResult.errorFileData, results.uploadedFiles[index]]
              : [results.uploadedFiles[index]];
          }
        });
      }

      // Add join table defaults
      if (!isEmpty(lookupJoinDefaultValues)) {
        const joinDefaultTables = Object.keys(lookupJoinDefaultValues);
        const joinTableAddQueries: AddInput[] = [];
        results.uploadedFiles.forEach((fileUploaded) => {
          joinDefaultTables.forEach((joinTableName: string) => {
            const input: RecordItem = {};
            const joinTableDefaultValues = lookupJoinDefaultValues[joinTableName];
            Object.keys(joinTableDefaultValues).forEach((key: string) => {
              // File id key will be undefined
              if (!joinTableDefaultValues[key]) {
                // This is the file id key
                input[key] = fileUploaded.id;
              } else {
                input[key] = joinTableDefaultValues[key];
              }
            });
            joinTableAddQueries.push({
              tableName: joinTableName,
              input
            });
          });
        });

        await Promise.all(joinTableAddQueries.map((query) => addRecordAsync(query)));
      }
      // When using files_list_id, the base table is not a join table, it is files directly
      if (parentRecordPage?.id && (parentFilesListId || filesListId)) {
        const finalFilesListId = filesListId || parentFilesListId;
        const filesListsFilesInputs: RecordItem[] = [];
        results.uploadedFiles.forEach((uploadedFile) => {
          filesListsFilesInputs.push({
            files_lists_id: finalFilesListId,
            files_id: uploadedFile.id,
            project_id:
              trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                ? currentSearchContextProjectId
                : currentProjectId
          });
        });

        await addRecordAsync({
          tableName: "files_lists_files",
          input: filesListsFilesInputs
        });
        if (parentRecordPage?.table_name && ["products", "companies"].includes(parentRecordPage?.table_name)) {
          // Trigger a is_featured check for products and companies table only
          fetch("/api/files/filesFeaturedUpdate", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              filesListId: finalFilesListId
            })
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              if (data.success) {
                queryClient.refetchQueries({
                  queryKey: ["table", tableName]
                });

                if (parentRecordInfo?.tableName) {
                  queryClient.refetchQueries({
                    queryKey: ["table", parentRecordInfo.tableName]
                  });
                }
                onSuccess?.();
              }
            });
        }
      }
      queryClient.refetchQueries({
        queryKey: ["table", tableName]
      });

      if (parentRecordInfo?.tableName) {
        queryClient.refetchQueries({
          queryKey: ["table", parentRecordInfo.tableName]
        });
      }

      onSuccess?.();

      if (trigger === FORM_TRIGGER_TYPES.SEARCH) {
        updateCurrentSearchContextProjectId(null);
        updateCurrentSearchContextRecordId(null);
      }
      setUploadResults((prevResults) => ({
        uploadCount: (prevResults.uploadCount || 0) + results.uploadCount,
        uploadedFiles: [...(prevResults.uploadedFiles || []), ...finalResult.uploadedFiles],
        errorFileData: [...(prevResults.errorFileData || []), ...finalResult.errorFileData]
      }));
    },
    [
      addRecordAsync,
      createdIn,
      createdInPath,
      defaultValues,
      filesListId,
      lookupJoinDefaultValues,
      onFilesChange,
      onClose,
      onSuccess,
      parentFilesListId,
      parentRecord,
      parentRecordInfo,
      parentRecordPage,
      queryClient,
      startUpload,
      tableName,
      trigger,
      updateCurrentSearchContextProjectId,
      updateCurrentSearchContextRecordId,
      currentProjectId,
      currentSearchContextProjectId,
      isJoinTable,
      triggeredBy,
      joinTableDefaultValues,
      returnFiles
    ]
  );

  const onRejectedFiles = useCallback(
    (rejectedFiles: File[]) => {
      setIsUploading(true);
      rejectFiles(rejectedFiles);
    },
    [rejectFiles]
  );

  const onUploadFinished = useCallback(() => {
    setIsUploading(false);
    onSuccess?.(uploadResults, triggeredBy);
  }, [onSuccess, triggeredBy, uploadResults]);

  const onReset = useCallback(() => {
    setUploadResults({
      uploadCount: 0,
      uploadedFiles: [],
      errorFileData: []
    });
    onClose({
      isOpen: false,
      currentAction: null,
      triggeredBy: null,
      actionProps: null
    });
    onSuccess?.(uploadResults, triggeredBy);
  }, [onClose, onSuccess, uploadResults, triggeredBy]);

  useEffect(() => {
    if (
      !parentRecordPage?.id ||
      !schemaInstance?.extendedSchema?.[parentRecordPage?.table_name || ""] ||
      filesListIdFetchPageIdRef?.current === parentRecordPage?.id ||
      skipFilesListCreate ||
      filesListId
    ) {
      return;
    }
    const tableProps = schemaInstance?.extendedSchema[parentRecordPage.table_name];
    if (
      parentRecordPage?.table_name === "files_lists" ||
      tableProps?.attributeIds?.includes("files_list_id") ||
      tableProps?.attributeIds?.includes("files_lists_id")
    ) {
      // We need to fetch this record to get the files_list_id
      filesListIdFetchPageIdRef.current = parentRecordPage.id as string;
      getParentFilesListId();
    }
  }, [
    parentRecordPage,
    schemaInstance?.extendedSchema,
    getParentFilesListId,
    parentFilesListId,
    skipFilesListCreate,
    filesListId
  ]);

  useEffect(() => {
    if (newFilesToUpload?.length) {
      setIsUploading(true);
      setUploadingQueue((prevQ) => [...(prevQ || []), ...newFilesToUpload]);
    }
  }, [newFilesToUpload]);

  return {
    isUploading,
    uploadStatus,
    uploadingQueue,
    onUploadFinished,
    onReset,
    triggeredBy,
    onSelectUploadedFiles,
    uploadFiles,
    onRejectedFiles,
    isCurrentFilesSetUpload,
    uploadResults
  };
};
export default useUploadFiles;
