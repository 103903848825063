"use client";

import noop from "lodash/noop";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useCurrentUser from "hooks/useCurrentUser";
import { NavigationItem } from "types/common";
import { ActionType } from "utils/constants";
import NavItemUI from "./NavItemUI";

type NavigationItemProps = {
  item: NavigationItem;
  isHidden?: boolean;
  isActive?: boolean;
  className?: string;
  onClick?: () => void;
  noHref?: boolean;
  isCollapsed?: boolean;
  showCollapsedUnreadIndicator?: boolean;
  onEditMenuItem?: (input: any) => void;
  menuPosition?: "top" | "left";
  recordPageId?: string;
  recordId?: string;
  isFolder?: boolean;
  isOpen?: boolean;
  count?: number;
};

const renameAction = {
  id: "rename",
  name: "Rename",
  icon: "folder",
  properties: {},
  type: ActionType.RENAME,
  tableName: ""
};

const NavigationItemWithActions = ({
  isHidden,
  isActive,
  item,
  onClick = noop,
  noHref = false,
  isCollapsed = false,
  onEditMenuItem,
  menuPosition = "left",
  recordPageId,
  recordId,
  isFolder,
  isOpen,
  className,
  count
}: NavigationItemProps) => {
  const nameRef = useRef<HTMLSpanElement>(null);
  const [name, setName] = useState(item.name);

  const currentUser = useCurrentUser();

  const [isEditing, setIsEditing] = useState(false);

  const handleActiveRename = () => {
    setIsEditing(true);
  };

  const handleUpdateMenuName = useCallback(async () => {
    const newName = nameRef.current?.textContent;

    if (!newName || !item?.id || newName === name) {
      setIsEditing(false);
      return;
    }

    setName(newName);

    const input = {
      id: item.id,
      name: newName
    };

    onEditMenuItem?.(input);
  }, [item, name, onEditMenuItem]);

  const handleBlur = useCallback(() => {
    if (!isEditing) return;

    setIsEditing(false);
    handleUpdateMenuName();
  }, [handleUpdateMenuName, isEditing]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLSpanElement>) => {
      e.stopPropagation();

      if (e.key === "Enter" || e.key === "Tab") {
        setIsEditing(false);
        handleUpdateMenuName();
      }
    },
    [handleUpdateMenuName]
  );

  const menuActions = useMemo(() => {
    if (!item.id || (!item.actions?.length && !item.is_folder) || menuPosition === "top" || !currentUser?.is_admin)
      return undefined;

    const finalActions = (item.actions || []).map((menuItm) => ({ ...menuItm, tableName: item.tableName }));

    if (item.id && !item.isViewOnlyMenu) {
      finalActions.push({ ...renameAction, onClick: handleActiveRename });
    }

    return finalActions;
  }, [currentUser?.is_admin, item.actions, item.id, item.isViewOnlyMenu, item.tableName, menuPosition, item.is_folder]);

  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        nameRef.current?.focus();
        // move caret to end
        const textLength = nameRef.current?.innerText.length as number;
        const range = document.createRange();
        const sel = window.getSelection();

        range.setStart(nameRef.current?.childNodes?.[0] as any, textLength);
        range.collapse(true);

        sel?.removeAllRanges();
        sel?.addRange(range);
      }, 100);
    }
  }, [isEditing]);

  return (
    <NavItemUI
      name={name}
      icon={isFolder ? (isOpen ? "folder-open" : "folder") : item.icon}
      item={item}
      actions={menuActions}
      menuPosition={menuPosition}
      nameRef={nameRef}
      isEditing={isEditing}
      onBlur={handleBlur}
      onKeypress={handleKeyPress}
      isHidden={isHidden}
      isActive={isActive}
      onClick={onClick}
      isCollapsed={isCollapsed}
      noHref={noHref}
      href={item.href as string}
      recordPageId={recordPageId}
      recordId={recordId}
      isFolder={isFolder}
      isOpen={isOpen}
      className={className}
      count={count}
    />
  );
};

export default NavigationItemWithActions;
