"use client";

import { Disclosure, Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import noop from "lodash/noop";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";

import useInLayout from "hooks/useInLayout";
import { NavigationItem } from "types/common";
import MenuSection from "./MenuSection";
import NavItemUI from "./NavItemUI";
import NavigationItemWithActions from "./NavigationItemWithActions";
import { hasChildWithThisPath, pathIsActive } from "./utils";

type NavigationItemProps = {
  item: NavigationItem;
  onClick?: () => void;
  noHref?: boolean;
  isActive?: boolean;
  level?: number;
  isCollapsed?: boolean;
  showCollapsedUnreadIndicator?: boolean;
  onEditMenuItem?: (input: any) => void;
  menuPosition?: "top" | "left";
  recordPageId?: string;
  recordId?: string;
  count?: number;
};

const NavigationItemComponent = ({
  item,
  onClick = noop,
  noHref = false,
  isActive,
  level = 0,
  isCollapsed = false,
  showCollapsedUnreadIndicator = false,
  onEditMenuItem,
  menuPosition = "left",
  recordPageId,
  recordId,
  count
}: NavigationItemProps) => {
  const [isOpen, setIsOpen] = useState(item.additionalConfig?.defaultOpen || false);
  const pathname = usePathname();

  const { isAdminPage, isRecord } = useInLayout();

  useEffect(() => {
    if (!pathname) return;
    const hasSubItemActive = hasChildWithThisPath(item, pathname);

    if (hasSubItemActive) {
      setIsOpen(hasSubItemActive);
    }
  }, [item, pathname]);

  const itemClassName = clsx(
    typeof item.href === "string"
      ? pathname &&
        pathname.includes(item.href) &&
        (!isRecord || pathname.split("/").length === item.href.split("/").length)
        ? ""
        : "dark:hover:bg-gray-750"
      : item.href?.pathname === pathname
        ? " "
        : " dark:hover:bg-gray-750"
  );

  const isDisclosureWrapperRoot = level === 0 || (menuPosition === "top" && level === 1);
  const DisclosureWrapper = isDisclosureWrapperRoot ? Disclosure : Disclosure.Panel;

  if (item.is_divider && menuPosition === "top") return null;
  if (!item.children?.length && (item.is_folder || item.is_section)) return null;

  if (item.is_divider) {
    return <div data-testid="NavigationItem" className="border-separator my-2.5 w-full border-b" />;
  }

  if (menuPosition === "top" && level === 0 && !item.children?.length) {
    return (
      <NavItemUI
        item={item}
        isActive={pathIsActive(item.href, pathname, isRecord, isAdminPage) || isActive || item.isActive}
        className={itemClassName}
        onClick={onClick}
        noHref={noHref}
        isCollapsed={isCollapsed}
        menuPosition={menuPosition}
        name={item.name}
        icon={item.icon}
        href={item.href as string}
        recordPageId={recordPageId}
        recordId={recordId}
        count={count}
      />
    );
  }

  if (menuPosition === "top" && level === 0 && !!item.children?.length) {
    return (
      <Popover>
        {({ open }) => (
          <>
            <Popover.Button>
              <NavItemUI
                isCollapsed={isCollapsed}
                name={item.name}
                icon={open ? "folder-open" : "folder"}
                item={item}
                href={item.href as string}
                count={count}
              />
            </Popover.Button>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-30 mt-3">
                <div className="max-h-[300px] min-w-[253px] overflow-hidden overflow-y-auto rounded-lg bg-white py-3 shadow-lg ring-1 ring-black ring-opacity-5">
                  {item.children?.map((child) => (
                    <NavigationItemComponent
                      key={child.name}
                      item={child}
                      isActive={pathIsActive(child.href, pathname, isRecord, isAdminPage) || child.isActive}
                      onClick={onClick}
                      level={level + 1}
                      noHref={noHref}
                      isCollapsed={isCollapsed}
                      onEditMenuItem={onEditMenuItem}
                      menuPosition={menuPosition}
                      recordPageId={recordPageId}
                      recordId={recordId}
                      count={count}
                    />
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  }

  if (item.children && item.children.length > 0) {
    return (
      <DisclosureWrapper
        as="div"
        key={item.name}
        data-testid="NavigationItem"
        className={clsx(item.is_section ? "border-separator  space-y-0" : "space-y-0", "select-none")}
        static={isDisclosureWrapperRoot ? undefined : true}
      >
        <Disclosure.Button
          as="div"
          onClick={() => setIsOpen(!isOpen)}
          className={clsx(
            "w-full cursor-pointer select-none",
            level > 0 && isCollapsed
              ? "pl-0 group-hover/nav:pl-4"
              : (level > 0 && menuPosition === "left") || (level > 1 && menuPosition === "top")
                ? "pl-4"
                : ""
          )}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.stopPropagation();
              e.preventDefault();
              setIsOpen(!isOpen);
            }
          }}
        >
          {item.is_folder && (
            <NavigationItemWithActions
              isCollapsed={isCollapsed}
              isOpen={isOpen}
              isFolder={true}
              className="mx-2"
              item={item}
              count={count}
              noHref
            />
          )}
          {item.is_section && <MenuSection name={item.name} isOpen={isOpen} isCollapsed={isCollapsed} />}
        </Disclosure.Button>
        <Transition
          unmount={false}
          show={isOpen}
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Disclosure.Panel
            className={clsx(
              "overflow-hidden py-0.5 ease-in-out",
              level > 0 && isCollapsed ? "pl-0 group-hover/nav:pl-4" : level > 0 ? "pl-4" : ""
            )}
            static
          >
            {item.children?.map((subItem) => (
              <NavigationItemComponent
                key={`nav_item_${subItem.id}_${subItem.name}`}
                item={subItem}
                isActive={pathIsActive(subItem.href, pathname, isRecord, isAdminPage) || subItem.isActive}
                onClick={onClick}
                level={level + 1}
                noHref={noHref}
                isCollapsed={isCollapsed}
                onEditMenuItem={onEditMenuItem}
                menuPosition={menuPosition}
                recordPageId={recordPageId}
                recordId={recordId}
                count={count}
              />
            ))}
          </Disclosure.Panel>
        </Transition>
      </DisclosureWrapper>
    );
  }

  return (
    <div
      data-testid="NavigationItem"
      className={clsx(
        "mx-2 py-[1px]",
        level > 0 && isCollapsed
          ? "pl-0 group-hover/nav:pl-4"
          : (level > 0 && menuPosition === "left") || (level > 1 && menuPosition === "top")
            ? "pl-4"
            : ""
      )}
    >
      <NavigationItemWithActions
        item={item}
        isActive={pathIsActive(item.href, pathname, isRecord, isAdminPage) || isActive || item.isActive}
        className={itemClassName}
        onClick={onClick}
        noHref={noHref}
        isCollapsed={isCollapsed}
        showCollapsedUnreadIndicator={showCollapsedUnreadIndicator}
        onEditMenuItem={onEditMenuItem}
        menuPosition={menuPosition}
        recordPageId={recordPageId}
        recordId={recordId}
        count={count}
      />
    </div>
  );
};

export default NavigationItemComponent;
