"use client";
import { useCallback, useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";

import NavigationItemComponent from "components/NavigationItem";
import { NavigationItem, RecordItem } from "types/common";

import { transformNavItemsToNavigate } from "./utils";

type NavigationItemsProps = {
  navigationMenuItems: NavigationItem[];
  noHref?: boolean;
  isCollapsed?: boolean;
  onEditMenuItem?: (input: any) => void;
  menuPosition?: "top" | "left";
  parentLayout?: "record" | "nested";
  selectedTab?: string;
  allowNavigation?: boolean;
  handleOnEditMenu?: boolean;
  recordPageId?: string;
  recordId?: string;
  countByNavItem?: RecordItem;
};

const NavigationItems = ({
  navigationMenuItems,
  noHref,
  isCollapsed,
  onEditMenuItem,
  menuPosition,
  parentLayout,
  selectedTab,
  allowNavigation,
  recordPageId,
  recordId,
  countByNavItem
}: NavigationItemsProps) => {
  const pathname = usePathname();
  const router = useRouter();

  const onEditMenuItemClick = useCallback(
    async (input: any) => {
      if (onEditMenuItem) {
        onEditMenuItem(input);
        return;
      }
      try {
        // await updateRecordAsync({ tableName: "ui_menu_items", input });
      } catch (error) {
        console.error(error);
      }
    },
    [onEditMenuItem]
  );

  useEffect(() => {
    if (!allowNavigation) return;

    const itemsToNavigate = transformNavItemsToNavigate(navigationMenuItems, parentLayout);

    const handleKeydown = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" && e.metaKey) {
        e.preventDefault();

        const currentNav = itemsToNavigate.findIndex((item) => {
          if (parentLayout === "record") {
            return item.href === pathname;
          } else if (parentLayout === "nested") {
            return item.name === selectedTab;
          }
        });
        const prevNav = itemsToNavigate[currentNav - 1];

        if (prevNav) {
          if (parentLayout === "record") {
            router.push(prevNav.href as string);
          } else if (parentLayout === "nested") {
            prevNav?.onClick?.();
          }
        }
      } else if (e.key === "ArrowDown" && e.metaKey) {
        e.preventDefault();

        const currentNavIndex = itemsToNavigate.findIndex((item) => {
          if (parentLayout === "record") {
            return item.href === pathname;
          } else if (parentLayout === "nested") {
            return item.name === selectedTab;
          }
        });
        const nextNav = itemsToNavigate[currentNavIndex + 1];

        if (nextNav) {
          if (parentLayout === "record") {
            router.push(nextNav.href as string);
          } else if (parentLayout === "nested") {
            nextNav?.onClick?.();
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeydown);
    return () => document.removeEventListener("keydown", handleKeydown);
  }, [allowNavigation, navigationMenuItems, parentLayout, pathname, router, selectedTab]);

  return (
    <div
      className={
        menuPosition === "top"
          ? "relative flex shrink-0 flex-row gap-x-1"
          : "relative flex shrink-0 flex-col overflow-y-auto pt-0.5"
      }
    >
      {navigationMenuItems.map((item) => (
        <NavigationItemComponent
          key={`${item.id}_${item.name}`}
          item={item}
          level={0}
          noHref={noHref}
          isCollapsed={isCollapsed}
          onEditMenuItem={onEditMenuItemClick}
          menuPosition={menuPosition}
          recordPageId={recordPageId}
          recordId={recordId}
          count={countByNavItem?.[item.id || ""]}
        />
      ))}
    </div>
  );
};

export default NavigationItems;
