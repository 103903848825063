import { NavigationItem } from "types/common";

export const getTabSlugOnRecordPage = (layoutsegments: string[]) => {
  if (layoutsegments.length < 3) return null;

  return layoutsegments[2];
};

// Checks for path `/r/:recordSlug`
export const isPathDetailPage = (layoutSegments: string[]) => {
  return layoutSegments?.[0] === "r" && layoutSegments?.length === 2;
};

export const getDefaultNavigationItem = (menuItems: NavigationItem[]): NavigationItem | null => {
  for (let i = 0; i < menuItems.length; i++) {
    const menuItem = menuItems[i];
    if (
      !menuItem.is_folder &&
      !menuItem.is_divider &&
      !menuItem.is_section &&
      !((menuItem.href as string) || "").startsWith("http")
    ) {
      return menuItem;
    }
    if (menuItem.children?.length) {
      const childMenuItem = getDefaultNavigationItem(menuItem.children);
      if (childMenuItem) {
        return childMenuItem;
      }
    }
  }
  return null;
};
