import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPagesColumnsByIds } from "lib/adminApi";
import { PAGE_QUERY_ADDITIONAL_PROP_TYPES, QueryHookOptions } from "types/common";
import useCurrentUser from "./useCurrentUser";

const useRelationPages = (additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES, options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const [relationPageIds, setRelationPagesIds] = useState<string[] | undefined>();
  const currentUser = useCurrentUser();
  const { data: relationPages, isLoading: isRelationPagesLoading } = useQuery({
    queryKey: ["relationpages", relationPageIds, currentUser?.org_id],
    queryFn: () =>
      getPagesColumnsByIds({
        supabaseClient,
        pageIds: relationPageIds || [],
        additionalDataProps,
        organizationId: currentUser?.org_id
      }),
    enabled: !!relationPageIds?.length,
    refetchOnMount: false,
    refetchInterval: false,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    ...options
  });

  const getRelationPages = useCallback((ids: string[]) => {
    setRelationPagesIds(ids);
  }, []);

  return {
    getRelationPages,
    data: relationPages?.data,
    isLoading: isRelationPagesLoading
  };
};

export default useRelationPages;
