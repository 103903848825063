"use client";

import clsx from "clsx";
import noop from "lodash/noop";
import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { RefObject, useRef } from "react";
import { useHover, useMediaQuery } from "usehooks-ts";

import AdminIcon from "components/CellGroup/AdminIcon";
import IconComp from "components/Icon";
import { NavigationItem, NavigationItemAction } from "types/common";
import MenuActions from "./MenuActions";

type NavItemUIProps = {
  isHidden?: boolean;
  isActive?: boolean;
  className?: string;
  item: NavigationItem;
  onClick?: () => void;
  isCollapsed?: boolean;
  menuPosition?: "top" | "left";
  icon?: string;
  name?: string;
  isEditing?: boolean;
  onKeypress?: (e: React.KeyboardEvent<HTMLSpanElement>) => void;
  onBlur?: () => void;
  href: string;
  isOpen?: boolean;
  isFolder?: boolean;
  actions?: NavigationItemAction[];
  nameRef?: RefObject<HTMLSpanElement>;
  noHref?: boolean;
  showCollapsedUnreadIndicator?: boolean;
  recordPageId?: string;
  recordId?: string;
  count?: number;
};

const NavItemUI = ({
  isHidden,
  isActive,
  className = "",
  item,
  onClick = noop,
  isCollapsed = false,
  menuPosition = "left",
  icon,
  name,
  isEditing,
  onKeypress,
  onBlur,
  href,
  isOpen,
  isFolder,
  actions,
  nameRef,
  noHref,
  showCollapsedUnreadIndicator = false,
  recordPageId,
  recordId,
  count
}: NavItemUIProps) => {
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const showRecordCount = !!(item.show_record_count && item.ui_page_id?.id);

  const Tag = isEditing || !href || noHref ? "div" : Link;
  const classNameLink = clsx(
    "cursor-pointer",
    isEditing && "ring-2 ring-primary-700 dark:ring-primary-dark-700",
    isActive
      ? "bg-gray-200 dark:bg-gray-700 navtext tpactive dark:text-gray-100"
      : "fill-gray-600 navtext dark:fill-gray-300 dark:text-gray-400",
    !isMobile && !isActive && "hover:bg-gray-200 hover:text-gray-800 dark:hover:bg-gray-750 dark:hover:text-gray-200",
    "group relative flex w-full items-center justify-between rounded-md py-2 text-[15px] leading-5",
    menuPosition === "top" ? "px-2.5" : "px-4",
    className
  );

  const handleOnClick = () => {
    if (noHref) {
      item.onClick?.();
    } else if (href) {
      router.push(href);
    } else if (!isEditing) {
      onClick();
    }
  };

  return (
    <div
      hidden={isHidden}
      className="relative flex w-full scale-100 appearance-none items-center transition-transform active:scale-[0.98]"
      ref={!isMobile ? hoverRef : null}
      onClick={(e) => (menuPosition === "left" && !isFolder ? e.stopPropagation() : undefined)}
    >
      <Tag
        // @ts-ignore
        href={!href || noHref ? undefined : href}
        className={classNameLink}
        onClick={handleOnClick}
        onMouseDown={noHref ? item.onMouseDown : undefined}
        target={item.open_in_new_tab ? "_blank" : undefined}
        rel="noreferrer"
      >
        <div className="flex items-center gap-x-2">
          <div
            className={clsx(
              isActive
                ? " tpnavicon text-gray-600 dark:text-gray-300 "
                : " group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400",
              "flex h-5 items-center align-middle transition-colors"
            )}
          >
            {icon && (
              <div className="h-4 w-4 !shrink-0">
                <IconComp name={icon as any} className="tpicon h-4 w-4" />
              </div>
            )}
            {showCollapsedUnreadIndicator && (
              <div
                className={clsx(
                  isCollapsed ? "block group-hover/nav:hidden" : "hidden",
                  "absolute -right-1 top-0 h-2 w-2 rounded-full bg-primary-700 dark:bg-primary-dark-700"
                )}
              />
            )}
          </div>

          <span
            className={clsx(
              "whitespace-nowrap",
              isCollapsed ? "hidden group-hover/nav:inline" : "inline",
              "tpnavitem min-h-[20px] min-w-[10px] focus:outline-none"
            )}
            contentEditable={isEditing}
            onKeyPress={onKeypress}
            onKeyDown={(e) => e.stopPropagation()}
            onBlur={onBlur}
            suppressContentEditableWarning
            ref={nameRef}
          >
            {name}
          </span>
          {isFolder && (
            <svg
              width="16"
              height="16"
              fill="none"
              className={clsx(isOpen ? "" : "-rotate-90", "h-4 w-4 text-gray-400 transition-transform")}
            >
              <path
                d="M8.67 10.18c-.324.426-1.016.426-1.34 0L4.646 6.665c-.375-.492.012-1.164.67-1.164h5.365c.659 0 1.046.672.671 1.164L8.671 10.18z"
                fill="currentColor"
              ></path>
            </svg>
          )}
          {(item.isAdminMenuItem || item.show_admin_only) && (
            <AdminIcon
              className={clsx("h-4 w-4", isCollapsed ? "hidden group-hover/nav:block" : "block")}
              label="Only visible to admins"
            />
          )}
          {!!(showRecordCount && count) && (
            <div
              className={clsx(
                isCollapsed ? "hidden group-hover/nav:flex" : "flex",
                "h-5 w-5 items-center justify-center rounded-full bg-neutral-300 p-2 text-3xs font-semibold dark:bg-neutral-dark-300"
              )}
            >
              {count}
            </div>
          )}
        </div>
      </Tag>

      {!!actions?.length && (
        <div className={clsx("absolute right-4 flex items-center gap-2", !item.showExposedActions && "top-1.5")}>
          <MenuActions actions={actions} isHovered={isHovered} item={item} recordPageId={recordPageId} />
        </div>
      )}
    </div>
  );
};

export default React.memo(NavItemUI);
